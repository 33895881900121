import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import './ShopifyBillingTest.css';
import { useTranslation } from 'react-i18next';

const ShopifyBillingTest = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState(null);
  const [planName, setPlanName] = useState('');
  const [billingInterval, setBillingInterval] = useState('month');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showCustomRequestForm, setShowCustomRequestForm] = useState(false);
  const [customRequestDescription, setCustomRequestDescription] = useState('');
  const [customRequestSubmitted, setCustomRequestSubmitted] = useState(false);
  const location = useLocation();

  const subscriptionTiers = {
    'Basic': {
      name: t('billing.basic'),
      description: t('billing.basicDescription'),
      monthlyPrice: '99 €',
      yearlyPrice: '950 €',
      features: [
        t('billing.basicFeature1'),
        t('billing.basicFeature2'),
        t('billing.basicFeature3'),
        t('billing.basicFeature4'),
        t('billing.basicFeature5'),
      ],
    },
    'Standard': {
      name: t('billing.standard'),
      description: t('billing.standardDescription'),
      monthlyPrice: '199 €',
      yearlyPrice: '1910 €',
      features: [
        t('billing.standardFeature1'),
        t('billing.standardFeature2'),
      ],
    },
    'Professional': {
      name: t('billing.professional'),
      description: t('billing.professionalDescription'),
      monthlyPrice: '399 €',
      yearlyPrice: '3830 €',
      features: [
        t('billing.professionalFeature1'),
        t('billing.professionalFeature2'),
      ],
    },
    'Enterprise': {
      name: t('billing.enterprise'),
      description: t('billing.enterpriseDescription'),
      monthlyPrice: t('billing.custom'),
      yearlyPrice: t('billing.custom'),
      features: [
        t('billing.enterpriseFeature1'),
        t('billing.enterpriseFeature2'),
        t('billing.enterpriseFeature3'),
        t('billing.enterpriseFeature4'),
      ],
    },
    'Free': {
      name: t('billing.free'),
      description: t('billing.freeDescription'),
      monthlyPrice: '0 €',
      yearlyPrice: '0 €',
      features: [
        t('billing.freeFeature1'),
        t('billing.freeFeature2'),
        t('billing.freeFeature3'),
        t('billing.freeFeature4'),
      ],
    },
  };


  useEffect(() => {
    fetchUserData();
    checkCustomRequestStatus();
  }, []);

  useEffect(() => {
    if (location.pathname === '/billing-success') {
      alert('Billing was successful!');
    } else if (location.pathname === '/billing-failed') {
      alert('Billing failed or was cancelled.');
    }
  }, [location]);

  const fetchUserData = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      if (!token) {
        setError('You are not logged in.');
        setLoading(false);
        return;
      }

      const response = await axios.get('https://unanimityaiapp-1.onrender.com/user', {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data) {
        setUser(response.data);
        setBillingInterval(response.data.billing_interval || 'month');
        setPlanName(response.data.subscription_plan || 'Basic');
      }
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      setError('Error fetching user data');
    } finally {
      setLoading(false);
    }
  };

  const checkCustomRequestStatus = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('https://unanimityaiapp-1.onrender.com/check_custom_request', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCustomRequestSubmitted(response.data.request_exists);
    } catch (error) {
      console.error('Error checking custom request status:', error);
    }
  };

  const handleCustomRequestSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.post('https://unanimityaiapp-1.onrender.com/submit_custom_request', 
        { description: customRequestDescription },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setCustomRequestSubmitted(true);
      setShowCustomRequestForm(false);
      alert('Your custom plan request has been submitted. We will review it and get back to you soon.');
    } catch (error) {
      console.error('Error submitting custom request:', error);
      alert('Failed to submit custom request. Please try again.');
    }
  };

  const handleSubscription = async (selectedPlanName) => {
    if (!user || !user.shop_name) {
      alert('Shop information not available');
      return;
    }
    if (selectedPlanName === 'Enterprise') {
      if (user.custom_plan === 'None') {
        if (customRequestSubmitted) {
          alert('Your custom plan request has already been submitted. We will review it and get back to you soon.');
        } else {
          setShowCustomRequestForm(true);
        }
        return;
      } else if (user.custom_plan === 'Activated') {
        try {
          setLoading(true);
          const token = sessionStorage.getItem('access_token');
          const response = await axios.post('https://unanimityaiapp-1.onrender.com/create_custom_shopify_subscription', 
            { billing_interval: 'year' },
            { headers: { Authorization: `Bearer ${token}` } }
          );
          window.location.href = response.data.confirmation_url;
        } catch (error) {
          console.error('Error handling custom subscription:', error);
          setError('Failed to create custom subscription');
        } finally {
          setLoading(false);
        }
        return;
      }
    }

    try {
      setLoading(true);
      const token = sessionStorage.getItem('access_token');
      const endpoint = user.is_subscribed ? 'https://unanimityaiapp-1.onrender.com/upgrade_subscription' : 'https://unanimityaiapp-1.onrender.com/create_shopify_subscription';
      const response = await axios.post(endpoint, {
        plan_name: selectedPlanName,
        billing_interval: billingInterval
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      window.location.href = response.data.confirmation_url;
    } catch (error) {
      console.error('Error handling subscription:', error);
      setError(user.is_subscribed ? 'Failed to upgrade subscription' : 'Failed to create subscription');
    } finally {
      setLoading(false);
    }
  };

  const deleteSubscription = async () => {
    try {
      setLoading(true);
      const token = sessionStorage.getItem('access_token');
      await axios.delete('https://unanimityaiapp-1.onrender.com/delete_shopify_subscription', {
        headers: { Authorization: `Bearer ${token}` }
      });
      alert('Subscription cancelled successfully');
      fetchUserData(); // Refresh user data
    } catch (error) {
      console.error('Error deleting subscription:', error);
      setError('Failed to delete subscription');
    } finally {
      setLoading(false);
    }
  };

  const CheckIcon = () => (
    <svg className="check-icon" viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
    </svg>
  );

  if (loading) {
    return <div className="loading">{t('billing.loading')}</div>;
  }

  if (error) {
    return <div className="error">{t('billing.error', { error })}</div>;
  }

  if (!user) {
    return <div className="no-user">{t('billing.noUserData')}</div>;
  }

  return (
    <div className="container">
      <h2 className="title">{t('billing.title')}</h2>
      <div className="user-info">
        <p>{t('billing.currentPlan', { plan: user.subscription_plan || t('billing.noActiveSubscription') })}</p>
        <p>{t('billing.subscriptionStatus', { status: user.is_subscribed ? t('billing.active') : t('billing.inactive') })}</p>
        <p>{t('billing.billingInterval', { interval: user.billing_interval || t('billing.na') })}</p>
      </div>
      
      <div className="billing-toggle">
        <span>{t('billing.monthlyS')}</span>
        <label className="switch">
          <input
            type="checkbox"
            checked={billingInterval === 'year'}
            onChange={() => {
              const newInterval = billingInterval === 'month' ? 'year' : 'month';
              setBillingInterval(newInterval);
            }}
          />
          <span className="slider"></span>
        </label>
        <span>{t('billing.annual')}</span>
        <span className="discount">{t('billing.annualDiscount')}</span>
      </div>

      {showCustomRequestForm && (
        <div className="overlay">
          <div className="custom-request-form">
            <h3>{t('billing.requestCustomPlan')}</h3>
            <form onSubmit={handleCustomRequestSubmit}>
              <textarea
                value={customRequestDescription}
                onChange={(e) => setCustomRequestDescription(e.target.value)}
                placeholder={t('billing.customPlanPlaceholder')}
                required
              />
              <div>
                <button type="submit">{t('billing.submit')}</button>
                <button type="button" onClick={() => setShowCustomRequestForm(false)}>{t('billing.cancel')}</button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="plans-grid">
        {Object.values(subscriptionTiers).map((tier) => (
          <div key={tier.name} className="plan-card">
            <h3>{tier.name}</h3>
            <p className="plan-description">{tier.description}</p>
            <p className="price">
              {billingInterval === 'year' ? tier.yearlyPrice : tier.monthlyPrice}
              <span>/{billingInterval === 'year' ? t('billing.yearly') : t('billing.monthly')}</span>
            </p>
            {tier.name !== t('billing.free') && (
              <button
                onClick={() => handleSubscription(tier.name)}
                disabled={loading || (user.is_subscribed && user.subscription_plan === tier.name)}
                className="subscribe-button"
              >
                {tier.name === t('billing.enterprise') 
                  ? (user.custom_plan === 'Activated' ? t('billing.subscribe') : t('billing.contactUs')) 
                  : (loading ? t('billing.processing') : (user.is_subscribed ? t('billing.upgrade') : t('billing.subscribe')))}
              </button>
            )}
            <ul className="feature-list">
              {tier.features.map((feature, index) => (
                <li key={index}><CheckIcon />{feature}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {user.is_subscribed && (
        <div className="cancel-subscription">
          <h3>{t('billing.cancelSubscription')}</h3>
          <button onClick={deleteSubscription} disabled={loading} className="cancel-button">
            {loading ? t('billing.processing') : t('billing.cancelSubscription')}
          </button>
        </div>
      )}
    </div>
  );
};

export default ShopifyBillingTest;