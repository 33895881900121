import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Sidebar.css';
import { useTranslation } from 'react-i18next';
import { FaHome, FaRobot, FaChartBar, FaArchive, FaUserPlus, FaCog, FaPowerOff, FaGlobe, FaInfoCircle } from 'react-icons/fa';

const Sidebar = ({ userRole }) => {
  const navigate = useNavigate();
  const [automation, setAutomation] = useState('');
  const [showHelp, setShowHelp] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    fetchAutomationStatus();
  }, []);

  const fetchAutomationStatus = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('https://unanimityaiapp-1.onrender.com/user', {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data) {
        setAutomation(response.data.automation || '');
      }
    } catch (error) {
      console.error('Failed to fetch automation status:', error);
    }
  };

  const handleAutomationToggle = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const newAutomationState = automation === 'on' ? 'off' : 'on';
      await axios.put('https://unanimityaiapp-1.onrender.com/update-automation', { automation: newAutomationState }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setAutomation(newAutomationState);
    } catch (error) {
      console.error('Failed to update automation state:', error);
      alert(t('errorUpdatingAutomation'));
    }
  };

  const handleLogout = () => {
    sessionStorage.removeItem('access_token');
    navigate('/');
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <img src="/menu_logo.png" alt={t('logo')} className="logo" />
      </div>
      <nav className="sidebar-nav">
        <ul>
          <li>
            <NavLink to="/dashboard" activeClassName="active">
              <FaHome /> {t('dashboard')}
            </NavLink>
          </li>
          {userRole === 'admin' && (
            <>
              <li>
                <NavLink to="/ai-assistants" activeClassName="active">
                  <FaRobot /> {t('aiAgentsLabs')}
                </NavLink>
              </li>
              <li>
                <NavLink to="/statistics" activeClassName="active">
                  <FaChartBar /> {t('statistics')}
                </NavLink>
              </li>
              <li>
                <NavLink to="/tickets/archive" activeClassName="active">
                  <FaArchive /> {t('archive')}
                </NavLink>
              </li>
              <li>
                <NavLink to="/create-agent" activeClassName="active">
                  <FaUserPlus /> {t('employee_creation')}
                </NavLink>
              </li>
              <li>
                <NavLink to="/parameters" activeClassName="active">
                  <FaCog /> {t('parameters')}
                </NavLink>
              </li>
            </>
          )}
        </ul>
      </nav>
      <div className="sidebar-footer">
        <div className="automation-toggle">
          <label className="switch">
            <input 
              type="checkbox" 
              checked={automation === 'on'} 
              onChange={handleAutomationToggle} 
            />
            <span className="slider"></span>
          </label>
          <span className="switch-label">
              {automation === 'on' ? t('on') : t('off')}
            </span>
          <FaInfoCircle 
            className="info-icon" 
            onMouseEnter={() => setShowHelp(true)} 
            onMouseLeave={() => setShowHelp(false)} 
          />
          {showHelp && (
            <div className="help-overlay">
              {t('automationHelp')}
            </div>
          )}
        </div>
        <div className="language-switcher">
          <FaGlobe />
          <select onChange={(e) => changeLanguage(e.target.value)} value={i18n.language}>
            <option value="en">English</option>
            <option value="fr">Français</option>
            <option value="es">Español</option>
            <option value="zh">中文</option>
          </select>
        </div>
        <button onClick={handleLogout} className="logout-button">
          <FaPowerOff /> {t('logout')}
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
