import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Welcome.css'; // Import the CSS file
import jwtDecode from 'jwt-decode';

const Welcome = () => {
  const [userDetails, setUserDetails] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('token');

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserDetails(decodedToken);
        console.log(`User Details:`, decodedToken);
      } catch (error) {
        console.error('Invalid token:', error);
        navigate('/'); // Redirect if the token is invalid
      }
    } else {
      navigate('/'); // Redirect if no token is found in URL
    }
  }, [navigate]);

  const copyToClipboard = () => {
    if (userDetails?.password) {
      navigator.clipboard.writeText(userDetails.password);
      alert('Password copied to clipboard');
    }
  };

  const handleStartClick = () => {
    window.location.href = 'https://platform.unanimity.ai';
  };

  return (
    <div className="welcome-container">
      <img src="unanimity_logo_512.png" alt="Unanimityai logo" className="unanimityai-logo" />
      <h2>Welcome to UnanimityAI</h2>
      <h3>Please, check your email address to get your credentials. You can change them once connected.</h3>
      <p>Feel free to send feedback or ask for new features, we are listening!</p>
      <h1>Thank you for your trust!</h1>
      {userDetails && (
        <>
          <p>Your email is: <strong>{userDetails.sub}</strong></p>
          <p>Your temporary password is: <strong>{userDetails.password}</strong> <button onClick={copyToClipboard} className="copy-button">Copy</button></p>
        </>
      )}
      <button onClick={handleStartClick} className="start-button">Start</button>
    </div>
  );
};

export default Welcome;
