import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CreateAgent.css';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaUserPlus, FaEdit, FaTrash, FaTimes } from 'react-icons/fa';

const CreateAgent = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState(undefined);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [agents, setAgents] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [currentAgent, setCurrentAgent] = useState(null);
  const [newDescription, setNewDescription] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      navigate('/');
      return;
    }

    const fetchData = async () => {
      await fetchUserData(token);
      await fetchAgents(token);
    };

    fetchData();
  }, [navigate]);

  const fetchUserData = async (token) => {
    try {
      const response = await axios.get('https://unanimityaiapp-1.onrender.com/user', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUser(response.data);
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      setUser(null);
    }
  };

  const fetchAgents = async (token) => {
    try {
      const response = await axios.get('https://unanimityaiapp-1.onrender.com/agents', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAgents(response.data);
    } catch (error) {
      console.error('Failed to fetch agents:', error);
    }
  };

  const handleCreateAgent = async (e) => {
    e.preventDefault();
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.post(
        'https://unanimityaiapp-1.onrender.com/create-agent',
        { name, email, description },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setName('');
      setEmail('');
      setDescription('');
      setCreateModalOpen(false);
      fetchAgents(token);
      alert('Agent created successfully');
    } catch (error) {
      console.error('Failed to create agent:', error);
    }
  };

  const handleEditDescription = (agent) => {
    setCurrentAgent(agent);
    setNewDescription(agent.description);
    setEditModalOpen(true);
  };

  const handleDeleteAgent = async (agentEmail) => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.delete(`https://unanimityaiapp-1.onrender.com/delete-employee/${agentEmail}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchAgents(token);
      alert('Agent deleted successfully');
    } catch (error) {
      console.error('Failed to delete agent:', error);
    }
  };

  const handleUpdateDescription = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.put(
        'https://unanimityaiapp-1.onrender.com/update-description',
        { email: currentAgent.email, description: newDescription },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setEditModalOpen(false);
      fetchAgents(token);
    } catch (error) {
      console.error('Failed to update description:', error);
    }
  };

  if (user === undefined) {
    return <div className="loading">{t('createAgent.loading')}</div>;
  }

  if (user.subscription_plan === 'free') {
    return (
      <div className="create-agent-container">
        <h2 className="page-title">{t('createAgent.manageEmployees')}</h2>
        <div className="not-accessible">
          <p>{t('createAgent.notAccessible')}</p>
          <Link to="/billing" className="upgrade-link">{t('createAgent.upgrade')}</Link>
        </div>
      </div>
    );
  }

  return (
    <div className="create-agent-container">
      <h2 className="page-title">{t('createAgent.manageEmployees')}</h2>
      <div className="agent-management">
        <div className="existing-agents-section">
          <h3>{t('createAgent.existingEmployees')}</h3>
          <div className="agent-list">
            {agents.map((agent) => (
              <div key={agent._id} className="agent-card">
                <div className="agent-info">
                  <span className="agent-name">{agent.name}</span>
                  <span className="agent-email">{agent.email}</span>
                </div>
                <div className="agent-actions">
                  <button className="action-button edit" onClick={() => handleEditDescription(agent)}>
                    <FaEdit />
                  </button>
                  <button className="action-button delete" onClick={() => handleDeleteAgent(agent.email)}>
                    <FaTrash />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <button onClick={() => setCreateModalOpen(true)} className="create-agent-button">
          <FaUserPlus /> {t('createAgent.createEmployee')}
        </button>
      </div>

      {editModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>{t('createAgent.editEmployee')}</h3>
            <button className="close-modal" onClick={() => setEditModalOpen(false)}><FaTimes /></button>
            <div className="agent-details">
              <p><strong>{t('createAgent.name')}:</strong> {currentAgent.name}</p>
              <p><strong>{t('createAgent.email')}:</strong> {currentAgent.email}</p>
            </div>
            <textarea
              value={newDescription}
              onChange={(e) => setNewDescription(e.target.value)}
              placeholder={t('createAgent.enterNewDescription')}
              className="description-textarea"
            ></textarea>
            <div className="modal-actions">
              <button onClick={handleUpdateDescription} className="update-button">
                {t('createAgent.updateDescription')}
              </button>
            </div>
          </div>
        </div>
      )}

      {createModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>{t('createAgent.createEmployee')}</h3>
            <button className="close-modal" onClick={() => setCreateModalOpen(false)}><FaTimes /></button>
            <form onSubmit={handleCreateAgent} className="create-agent-form">
              <div className="form-group">
                <label htmlFor="name">{t('createAgent.name')}</label>
                <input
                  type="text"
                  id="name"
                  placeholder={t('createAgent.enterEmployeeName')}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">{t('createAgent.email')}</label>
                <input
                  type="text"
                  id="email"
                  placeholder={t('createAgent.enterEmployeeEmail')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="description">{t('createAgent.description')}</label>
                <textarea
                  id="description"
                  placeholder={t('createAgent.enterEmployeeDescription')}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                ></textarea>
              </div>
              <div className="modal-actions">
                <button type="submit" className="create-button">
                  {t('createAgent.createEmployee')}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateAgent;