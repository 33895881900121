// src/components/LanguageSwitcher.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="language-switcher">
      <button onClick={() => changeLanguage('en')} aria-label="Switch to English">
        <span className="flag-emoji" role="img" aria-label="English">🇬🇧</span>
      </button>
      <button onClick={() => changeLanguage('fr')} aria-label="Passer en français">
        <span className="flag-emoji" role="img" aria-label="Français">🇫🇷</span>
      </button>
      <button onClick={() => changeLanguage('es')} aria-label="Cambiar a español">
        <span className="flag-emoji" role="img" aria-label="Español">🇪🇸</span>
      </button>
      <button onClick={() => changeLanguage('zh')} aria-label="切换到中文">
        <span className="flag-emoji" role="img" aria-label="中文">🇨🇳</span>
      </button>
    </div>
  );
};

export default LanguageSwitcher;