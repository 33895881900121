import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import TicketTableArchive from './TicketTableArchive';
import { useTranslation } from 'react-i18next';

const Archive = () => {
  const { t } = useTranslation();
  const [archivedTickets, setArchivedTickets] = useState([]);
  const [user, setUser] = useState(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      navigate('/');
      return;
    }
    fetchUserData(token);
  }, [navigate]);

  useEffect(() => {
    if (user === null) {
      navigate('/');
    }
  }, [user, navigate]);

  useEffect(() => {
    if (user) {
      const token = sessionStorage.getItem('access_token');
      fetchArchivedTickets(token);
    }
  }, [user]);

  const fetchUserData = async (token) => {
    try {
      const response = await axios.get('https://unanimityaiapp-1.onrender.com/user', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUser(response.data);
    } catch (error) {
      console.error(t('archiveP.fetchUserDataError'), error);
      setUser(null);
    }
  };

  const fetchArchivedTickets = async (token) => {
    try {
      const response = await axios.get('https://unanimityaiapp-1.onrender.com/tickets/archived', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setArchivedTickets(response.data);
    } catch (error) {
      console.error(t('archiveP.fetchArchivedTicketsError'), error);
    }
  };

  const handleTicketClick = (ticketId) => {
    navigate(`/tickets/${ticketId}/messages`);
  };

  if (user === undefined) {
    return <div className="loading">{t('archive.loading')}</div>;
  }

  return (
    <div className="archive-container">
      <h3>{t('archiveP.archivedTickets')}</h3>
      <TicketTableArchive tickets={archivedTickets} user={user} onTicketClick={handleTicketClick} />
    </div>
  );
};

export default Archive;