import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const token = sessionStorage.getItem('access_token');
  const { ticketId } = useParams();

  if (!token) {
    return <Navigate to="/" replace />;
  }

  let decodedToken;
  try {
    decodedToken = jwtDecode(token);
  } catch (error) {
    console.error('Invalid token:', error);
    sessionStorage.removeItem('access_token');
    return <Navigate to="/" replace />;
  }

  const userRole = decodedToken.role;
  if (roles && !roles.includes(userRole)) {
    return <Navigate to="/dashboard" replace />;
  }

  return <Component {...rest} ticketId={ticketId} isAuthenticated={true} userRole={userRole} />;
};

export default PrivateRoute;
