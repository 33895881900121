import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <img src="unanimity_logo_512.png" alt="Unanimityai logo" className="unanimityai-logo" />
      <h1>Unanimity AI Privacy Policy</h1>
      <p><strong>Last Updated: 05/17/2024</strong></p>
      <p>Thank you for using Unanimity AI. We are committed to protecting your privacy and ensuring the security of the information you share with us. This privacy policy describes the types of data we collect, how we use them, the measures we take to protect them, and your rights regarding your personal information.</p>
      
      <h2>1. Information We Collect</h2>
      <p>We collect different types of information in the course of our services:</p>
      
      <h3>a. Information provided by the user:</h3>
      <ul>
        <li>Name, email address, and other contact information during registration.</li>
        <li>Order details, shopping carts, and tracking of packages.</li>
        <li>Information on customer support requests.</li>
      </ul>
      
      <h3>b. Automatically generated information:</h3>
      <ul>
        <li>Logs of conversations between users and AI agents.</li>
        <li>Usage data of services, including interactions with AI agents.</li>
      </ul>
      
      <h2>2. Use of Information</h2>
      <p>We use the collected information to:</p>
      <ul>
        <li>Provide, operate, and improve our services.</li>
        <li>Automate and optimize customer support.</li>
        <li>Allow businesses to track and analyze the performance of AI agents.</li>
        <li>Improve AI models by using resolved tickets to refine AI agents' responses.</li>
      </ul>
      
      <h2>3. Sharing of Information</h2>
      <p>We do not sell, rent, or distribute your personal information to third parties, except in the following cases:</p>
      <ul>
        <li>With your explicit consent.</li>
        <li>To comply with legal or regulatory obligations.</li>
        <li>To protect the safety, rights, or property of Unanimity AI, our users, or the public.</li>
      </ul>
      
      <h2>4. Security of Information</h2>
      <p>We implement technical and organizational security measures to protect your information against unauthorized access, disclosure, alteration, or destruction. These measures include:</p>
      <ul>
        <li>Encryption of data in transit and at rest.</li>
        <li>Strict access controls and regular audits.</li>
        <li>Storing information in secure databases.</li>
      </ul>
      
      <h2>5. Your Rights</h2>
      <p>You have the right to:</p>
      <ul>
        <li>Access your personal information that we hold.</li>
        <li>Correct or update your information.</li>
        <li>Request the deletion of your personal information.</li>
        <li>Withdraw your consent to the processing of your personal data.</li>
      </ul>
      <p>To exercise these rights, please contact us at data@unanimity.ai.</p>
      
      <h2>6. Data Retention</h2>
      <p>We retain your personal information only as long as necessary to achieve the purposes described in this privacy policy, unless a longer retention period is required or permitted by law.</p>
      
      <h2>7. Changes to this Privacy Policy</h2>
      <p>We may update this privacy policy from time to time. We will inform you of any significant changes by email or through a notification on our website.</p>
      
      <h2>8. Contact</h2>
      <p>If you have any questions or concerns about this privacy policy or our privacy practices, please contact us at:</p>
      <address>
        Unanimity AI<br />
        Cagnes sur mer, 06800 France<br />
        contact@unanimity.ai
      </address>
      
      <p>By using Unanimity AI, you agree to the terms of this privacy policy. If you do not agree with these terms, please do not use our services.</p>
      
      <p><strong>Thank you for your trust in Unanimity AI.</strong></p>
    </div>
  );
};

export default PrivacyPolicy;
