import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ChatbotSettings.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ChatbotSettings = () => {
  const { t } = useTranslation();
  const [settings, setSettings] = useState({
    primary_color: '#000000',
    secondary_color: '#FFFFFF',
    tertiary_color: '#333333',
    company_name: 'Company Name',
    agent_name: 'bot',
    greetings_message: 'Hey, how can I help you?'
  });
  const [embedCode, setEmbedCode] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      navigate('/');
      return;
    }

    fetchChatbotSettings(token);
    generateEmbedCode(token);
  }, [navigate]);

  const fetchChatbotSettings = async (token) => {
    try {
      const response = await axios.get('https://unanimityaiapp-1.onrender.com/chatbot/settings', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setSettings(response.data);
    } catch (error) {
      console.error('Failed to fetch chatbot settings:', error);
    }
  };

  const handleChange = (e) => {
    setSettings({ ...settings, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    const token = sessionStorage.getItem('access_token');
    try {
      console.log('Settings to be saved:', settings);
      await axios.post(
        'https://unanimityaiapp-1.onrender.com/chatbot/settings',
        settings,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert(t('chatbotSettings.settingsSaved'));
    } catch (error) {
      console.error('Failed to save settings:', error);
    }
  };

  const generateEmbedCode = async (token) => {
    try {
      const response = await axios.get('https://unanimityaiapp-1.onrender.com/chatbot/embed', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setEmbedCode(response.data.embed_code);
    } catch (error) {
      console.error('Failed to generate embed code:', error);
    }
  };

  return (
    <div className="chatbot-settings-container">
      <h2>{t('chatbotSettings.title')}</h2>
      <div className="form-group">
        <label htmlFor="company_name">{t('chatbotSettings.companyName')}</label>
        <input
          type="text"
          id="company_name"
          name="company_name"
          value={settings.company_name}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="agent_name">{t('chatbotSettings.aiAgentName')}</label>
        <input
          type="text"
          id="agent_name"
          name="agent_name"
          value={settings.agent_name}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="greetings_message">{t('chatbotSettings.greetingMessage')}</label>
        <input
          type="text"
          id="greetings_message"
          name="greetings_message"
          value={settings.greetings_message}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="primary_color">{t('chatbotSettings.primaryColor')}</label>
        <input
          type="color"
          id="primary_color"
          name="primary_color"
          value={settings.primary_color}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="secondary_color">{t('chatbotSettings.secondaryColor')}</label>
        <input
          type="color"
          id="secondary_color"
          name="secondary_color"
          value={settings.secondary_color}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="tertiary_color">{t('chatbotSettings.tertiaryColor')}</label>
        <input
          type="color"
          id="tertiary_color"
          name="tertiary_color"
          value={settings.tertiary_color}
          onChange={handleChange}
        />
      </div>
      <button onClick={handleSave} className="glass-button">{t('chatbotSettings.save')}</button>
      <h3>{t('chatbotSettings.userId')}</h3>
      <textarea 
        readOnly 
        value={embedCode} 
        style={{ width: '100%', height: '100px' }} 
      />
    </div>
  );
};

export default ChatbotSettings;