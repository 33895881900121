// src/components/Layout.js
import React from 'react';
import Sidebar from './Sidebar';
import { Outlet } from 'react-router-dom';

const Layout = ({ userRole }) => {
  return (
    <div className="layout-container">
      <Sidebar userRole={userRole} />
      <div className="main-content">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
