import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AIAssistantManagement.css';
import CreateForm from './CreateForm';
import KnowledgeBoard from './KnowledgeBoard';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaRobot, FaPen, FaTrash, FaPlay, FaPlus, FaMagic, FaBook, FaWpforms } from 'react-icons/fa';

const AIAssistantManagement = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState(undefined);
  const [aiAssistants, setAIAssistants] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [name, setName] = useState('');
  const [prompt, setPrompt] = useState('');
  const [questions, setQuestions] = useState(['', '', '', '']);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [promptPopupOpen, setPromptPopupOpen] = useState(false);
  const [promptInput, setPromptInput] = useState('');
  const [promptMode, setPromptMode] = useState('edit');
  const [activeTab, setActiveTab] = useState('knowledge');
  const navigate = useNavigate();

  const questionTitles = [
    t('aiAssistant.question1'),
    t('aiAssistant.question2'),
    t('aiAssistant.question3'),
    t('aiAssistant.question4'),
  ];

  useEffect(() => {
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      navigate('/');
      return;
    }

    fetchUserData(token);
  }, [navigate]);

  useEffect(() => {
    if (user === null) {
      navigate('/');
    }
  }, [user, navigate]);

  useEffect(() => {
    if (user) {
      const token = sessionStorage.getItem('access_token');
      fetchAIAssistants(token);
    }
  }, [user]);

  const fetchUserData = async (token) => {
    try {
      const response = await axios.get('https://unanimityaiapp-1.onrender.com/user', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUser(response.data);
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      setUser(null);
    }
  };

  const fetchAIAssistants = async (token) => {
    try {
      const response = await axios.get('https://unanimityaiapp-1.onrender.com/ai-assistants', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAIAssistants(response.data);
    } catch (error) {
      console.error('Failed to fetch AI assistants:', error);
    }
  };

  const handleDeleteAIAssistant = async (assistantId) => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.delete(`https://unanimityaiapp-1.onrender.com/ai-assistants/${assistantId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert('AI assistant deleted successfully');
      fetchAIAssistants(token);
    } catch (error) {
      console.error('Failed to delete AI assistant:', error);
      alert('Failed to delete AI assistant.');
    }
  };

  const handleCreateAIAssistant = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.post(
        'https://unanimityaiapp-1.onrender.com/ai-assistants',
        { name, prompt },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log('AI assistant created:', response.data);
      fetchAIAssistants(token);
      resetForm();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.error('Failed to create AI assistant:', error.response.data.detail);
      } else {
        console.error('Failed to create AI assistant:', error);
      }
    }
  };

  const handleUpdateAIAssistant = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.put(
        `https://unanimityaiapp-1.onrender.com/ai-assistants/${selectedAssistant._id}`,
        { name, prompt },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log('AI assistant updated');
      fetchAIAssistants(token);
      resetForm();
    } catch (error) {
      console.error('Failed to update AI assistant:', error);
    }
  };

  const handleSelectAssistant = (assistant) => {
    if (selectedAssistant === assistant) {
      resetForm();
    } else {
      setSelectedAssistant(assistant);
      setName(assistant.name);
      setPrompt(assistant.prompt);
    }
  };

  const handleSetRunning = async (assistantId) => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.put(
        `https://unanimityaiapp-1.onrender.com/ai-assistants/${assistantId}/running`,
        null,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      fetchAIAssistants(token);
    } catch (error) {
      console.error('Failed to set AI assistant as running:', error);
    }
  };

  const resetForm = () => {
    setSelectedAssistant(null);
    setName('');
    setPrompt('');
    setPromptInput('');
  };

  const handleOpenPopup = () => {
    setShowPopup(true);
    setCurrentQuestionIndex(0);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setCurrentQuestionIndex(0);
  };

  const handleQuestionChange = (index, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = value;
    setQuestions(updatedQuestions);
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePromptPopupOpen = () => {
    setPromptInput(prompt);
    setPromptMode('edit');
    setPromptPopupOpen(true);
  };

  const handlePromptPopupClose = () => {
    setPromptPopupOpen(false);
    setPromptMode('edit');
  };

  const handlePromptSave = () => {
    setPrompt(promptInput);
    handlePromptPopupClose();
  };

  const handleSwitchToGenerate = () => {
    setPromptMode('generate');
    setQuestions(['', '', '', '']);
  };

  const handleGenerateInstructions = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.post(
        'https://unanimityaiapp-1.onrender.com/generate-instructions',
        { questions },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setPromptInput(response.data.instructions);
      setPromptMode('edit');
    } catch (error) {
      console.error('Failed to generate instructions:', error);
    }
  };

  const handlePromptChange = (e) => {
    setPromptInput(e.target.value);
  };

  const getPromptPreview = (text) => {
    const maxLength = 50;
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  if (user === undefined) {
    return <div className="loading">{t('aiAssistant.loading')}</div>;
  }

  if (user === null) {
    return null;
  }

  return (
    <div className="ai-assistant-management">
      <h2 className="section-title"><FaRobot /> {t('aiAssistant.title')}</h2>
      <div className="management-container">
        <div className="assistants-list">
          <h3>{t('aiAssistant.existingAssistants')}</h3>
          <ul>
            {aiAssistants.map((assistant) => (
              <li
                key={assistant._id}
                className={`assistant-item ${selectedAssistant === assistant ? 'selected' : ''}`}
                onClick={() => handleSelectAssistant(assistant)}
              >
                <span className="assistant-name">{assistant.name}</span>
                <div className="assistant-actions">
                  {assistant.is_running ? (
                    <span className="running-status">{t('aiAssistant.running')}</span>
                  ) : (
                    <button
                      className="icon-button set-running-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSetRunning(assistant._id);
                      }}
                      aria-label={t('aiAssistant.setRunningAriaLabel', { name: assistant.name })}
                    >
                      <FaPlay />
                    </button>
                  )}
                  <button
                    className="icon-button delete-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteAIAssistant(assistant._id);
                    }}
                    aria-label={t('aiAssistant.deleteAriaLabel', { name: assistant.name })}
                  >
                    <FaTrash />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="assistant-form">
          <h3>{selectedAssistant ? t('aiAssistant.editAssistant') : t('aiAssistant.createAssistant')}</h3>
          <div className="form-group">
            <label htmlFor="name">{t('aiAssistant.name')}:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              aria-required="true"
            />
          </div>
          <div className="form-group">
            <label htmlFor="prompt">{t('aiAssistant.prompt')}:</label>
            <div
              className="prompt-preview"
              onClick={handlePromptPopupOpen}
              title={t('aiAssistant.clickToEditPrompt')}
            >
              {getPromptPreview(prompt)}
            </div>
          </div>
          <div className="button-group">
            {selectedAssistant ? (
              <button className="primary-button update-button" onClick={handleUpdateAIAssistant}>
                <FaPen /> {t('aiAssistant.updateAssistant')}
              </button>
            ) : (
              <button className="primary-button create-button" onClick={handleCreateAIAssistant}>
                <FaPlus /> {t('aiAssistant.createAssistant')}
              </button>
            )}
          </div>
        </div>
      </div>
      
      <div className="tabs-container">
        <div className="tabs">
          <button
            className={`tab-button ${activeTab === 'knowledge' ? 'active' : ''}`}
            onClick={() => setActiveTab('knowledge')}
          >
            <FaBook /> {t('aiAssistant.knowledgeBoard')}
          </button>
          <button
            className={`tab-button ${activeTab === 'form' ? 'active' : ''}`}
            onClick={() => setActiveTab('form')}
          >
            <FaWpforms /> {t('aiAssistant.createForm')}
          </button>
        </div>
        <div className="tab-content">
          {activeTab === 'knowledge' ? <KnowledgeBoard /> : <CreateForm />}
        </div>
      </div>
      {showPopup && (
        <div className="modalai">
          <div className="modal-content">
            <h3>{t('aiAssistant.generateInstructions')}</h3>
            <div className="form-group">
              <label htmlFor={`question${currentQuestionIndex + 1}`}>
                {questionTitles[currentQuestionIndex]}
              </label>
              <input
                type="text"
                id={`question${currentQuestionIndex + 1}`}
                value={questions[currentQuestionIndex]}
                onChange={(e) => handleQuestionChange(currentQuestionIndex, e.target.value)}
              />
            </div>
            <div className="button-group">
              {currentQuestionIndex < questions.length - 1 ? (
                <button className="primary-button next-button" onClick={handleNextQuestion}>
                  {t('aiAssistant.next')}
                </button>
              ) : (
                <button className="primary-button generate-button" onClick={handleGenerateInstructions}>
                  {t('aiAssistant.generate')}
                </button>
              )}
              <button className="secondary-button cancel-button" onClick={handleClosePopup}>
                {t('aiAssistant.cancel')}
              </button>
            </div>
          </div>
        </div>
      )}

      {promptPopupOpen && (
        <div className="modalai">
          <div className="modal-content">
            <h3>{t('aiAssistant.editPrompt')}</h3>
            <div className="form-group">
              <label htmlFor="promptInput">{t('aiAssistant.prompt')}:</label>
              <textarea
                id="promptInput"
                value={promptInput}
                onChange={handlePromptChange}
                rows="10"
                aria-required="true"
              ></textarea>
            </div>
            <div className="button-group">
              <button className="primary-button submit-button" onClick={handlePromptSave}>
                {t('aiAssistant.save')}
              </button>
              <button className="secondary-button cancel-button" onClick={handlePromptPopupClose}>
                {t('aiAssistant.cancel')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AIAssistantManagement;