import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './KnowledgeBoard.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaFile, FaGlobe, FaPlus, FaTrash, FaEye } from 'react-icons/fa';

const KnowledgeBoard = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState(undefined);
  const [files, setFiles] = useState([]);
  const [fileUrl, setFileUrl] = useState('');
  const [pdfOverlay, setPdfOverlay] = useState({ show: false, message: '', success: false });
  const [webOverlay, setWebOverlay] = useState({ show: false, message: '', success: false });
  const [webJson2Content, setWebJson2Content] = useState([]);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const [newUrl, setNewUrl] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      navigate('/'); 
      return;
    }

    fetchUserData(token);
  }, [navigate]);

  useEffect(() => {
    if (user === null) {
      navigate('/'); 
    }
  }, [user, navigate]);

  useEffect(() => {
    if (user) {
      const token = sessionStorage.getItem('access_token');
      fetchUserFiles(token);
      fetchWebJson2Content(token);
    }
  }, [user]);

  const fetchUserData = async (token) => {
    try {
      const response = await axios.get('https://unanimityaiapp-1.onrender.com/user', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUser(response.data);
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      setUser(null);
    }
  };

  const fetchUserFiles = async (token) => {
    try {
      const response = await axios.get('https://unanimityaiapp-1.onrender.com/list-files/', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setFiles(response.data.files);
    } catch (error) {
      console.error('Failed to fetch files:', error);
      alert('Failed to fetch files.');
    }
  };

  const fetchWebJson2Content = async (token) => {
    try {
      const response = await axios.get('https://unanimityaiapp-1.onrender.com/get-web-json-2/', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setWebJson2Content(JSON.parse(response.data.content));
    } catch (error) {
      console.error('Failed to fetch web_json-2.txt:', error);
      alert('Failed to fetch web_json-2.txt.');
    }
  };

  const handleFileSelect = async (event) => {
    const selected = event.target.files[0];
  
    if (!selected) {
      alert('Please select a file to upload.');
      return;
    }
  
    const formData = new FormData();
    formData.append('file', selected);
  
    setPdfOverlay({ show: true, message: t('knowledgeBoard.uploading'), success: false });
  
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.post('https://unanimityaiapp-1.onrender.com/upload-file/', formData, {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
      });
  
      setPdfOverlay({ show: true, message: t('knowledgeBoard.uploadSuccess'), success: true });
      fetchUserFiles(token);
    } catch (error) {
      console.error('Upload failed:', error);
      setPdfOverlay({ show: true, message: t('knowledgeBoard.uploadError'), success: false });
    } finally {
      setTimeout(() => setPdfOverlay({ show: false, message: '', success: false }), 2000);
    }
  };

  const handleDeleteFile = async (fileKey) => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.delete(`https://unanimityaiapp-1.onrender.com/delete-file/?file_key=${encodeURIComponent(fileKey)}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert('File deleted successfully.');
      fetchUserFiles(token);
    } catch (error) {
      console.error('Failed to delete file:', error);
      alert('Failed to delete file.');
    }
  };

  const fetchAndViewFile = async (fileKey) => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get(`https://unanimityaiapp-1.onrender.com/generate-view-url/?file_key=${fileKey}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setFileUrl(response.data.url);
    } catch (error) {
      console.error('Failed to fetch file URL:', error);
      alert('Failed to view file.');
    }
  };

  const updateWebJson2 = async (title, url) => {
    setWebOverlay({ show: true, message: t('knowledgeBoard.updating'), success: false });
  
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.put(
        `https://unanimityaiapp-1.onrender.com/update-web-json-2/?title=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}`,
        null,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setWebOverlay({ show: true, message: t('knowledgeBoard.updateSuccess'), success: true });
      fetchWebJson2Content(token);
    } catch (error) {
      console.error('Failed to update web_json-2.txt:', error);
      setWebOverlay({ show: true, message: t('knowledgeBoard.updateError'), success: false });
    } finally {
      setTimeout(() => setWebOverlay({ show: false, message: '', success: false }), 2000);
    }
  };

  const handleUpdateWebJson2 = () => setShowUpdateModal(true);

  const handleUpdateModalSubmit = async () => {
    if (newTitle && newUrl) {
      await updateWebJson2(newTitle, newUrl);
      setNewTitle('');
      setNewUrl('');
      setShowUpdateModal(false);
    } else {
      alert('Title and URL must be provided.');
    }
  };

  const handleUpdateModalClose = () => {
    setShowUpdateModal(false);
    setNewTitle('');
    setNewUrl('');
  };

  const handleRemoveWebJsonEntry = async (title) => {
    const updatedContent = webJson2Content.filter((entry) => entry.title !== title);
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.put(
        `https://unanimityaiapp-1.onrender.com/delete-web-json-2/?title=${encodeURIComponent(title)}`,
        null,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setWebJson2Content(updatedContent);
    } catch (error) {
      console.error('Failed to remove web_json-2.txt entry:', error);
      alert('Failed to remove web_json-2.txt entry.');
    }
  };

  if (user === undefined) {
    return <div className="loading">{t('knowledgeBoard.loading')}</div>;
  }

  if (user === null) {
    return null;
  }

  return (
    <div className="knowledge-board">
      <h3 className="section-title"><FaFile /> {t('knowledgeBoard.title')}</h3>
      <div className="resources-container">
        <div className="file-resources">
          <h4><FaFile /> {t('knowledgeBoard.fileResources')}</h4>
          <div className="file-list">
            {files.map((file) => (
              <div key={file} className="file-item">
                <span className="file-name" onClick={() => fetchAndViewFile(file)}>{file.split('/').pop()}</span>
                <div className="file-actions">
                  <button className="icon-button view-button" onClick={() => fetchAndViewFile(file)} aria-label={t('knowledgeBoard.viewFileAriaLabel', { file: file.split('/').pop() })}>
                    <FaEye />
                  </button>
                  <button className="icon-button delete-button" onClick={() => handleDeleteFile(file)} aria-label={t('knowledgeBoard.deleteFileAriaLabel', { file: file.split('/').pop() })}>
                    <FaTrash />
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="file-upload">
            <input
              id="fileInput"
              type="file"
              onChange={handleFileSelect}
              accept=".pdf,.doc,.docx,.txt"
              style={{ display: 'none' }}
            />
            <button className="secondary-button upload-button" onClick={() => document.getElementById('fileInput').click()}>
              <FaPlus /> {t('knowledgeBoard.uploadFile')}
            </button>
          </div>
        </div>
        
        <div className="web-resources">
          <h4><FaGlobe /> {t('knowledgeBoard.webResources')}</h4>
          <div className="web-json-content">
            {webJson2Content.map((entry) => (
              <div key={entry.title} className="web-json-item">
                <span className="web-json-title">{entry.title}</span>
                <a href={entry.url} target="_blank" rel="noopener noreferrer" className="web-json-url">
                  {entry.url}
                </a>
                <button
                  className="icon-button delete-button"
                  onClick={() => handleRemoveWebJsonEntry(entry.title)}
                  aria-label={t('knowledgeBoard.removeWebEntryAriaLabel', { title: entry.title })}
                >
                  <FaTrash />
                </button>
              </div>
            ))}
          </div>
          <button className="secondary-button add-web-resource-button" onClick={handleUpdateWebJson2}>
            <FaPlus /> {t('knowledgeBoard.addWebResource')}
          </button>
        </div>
      </div>

      {showUpdateModal && (
        <div className="modalweb">
          <div className="modal-content">
            <h3>{t('knowledgeBoard.addNewWebsite')}</h3>
            <div className="form-group">
              <label htmlFor="newTitle">{t('knowledgeBoard.title')}:</label>
              <input
                type="text"
                id="newTitle"
                value={newTitle}
                onChange={(e) => setNewTitle(e.target.value)}
                aria-required="true"
              />
            </div>
            <div className="form-group">
              <label htmlFor="newUrl">{t('knowledgeBoard.url')}:</label>
              <input
                type="text"
                id="newUrl"
                value={newUrl}
                onChange={(e) => setNewUrl(e.target.value)}
                aria-required="true"
              />
            </div>
            <div className="modal-buttons">
              <button className="primary-button submit-button" onClick={handleUpdateModalSubmit}>
                {t('knowledgeBoard.submit')}
              </button>
              <button className="secondary-button cancel-button" onClick={handleUpdateModalClose}>
                {t('knowledgeBoard.cancel')}
              </button>
            </div>
          </div>
        </div>
      )}
      {pdfOverlay.show && (
        <div className="overlay">
          <div className="overlay-content">
            {!pdfOverlay.success && <div className="spinner"></div>}
            <p>{pdfOverlay.message}</p>
          </div>
        </div>
      )}

      {webOverlay.show && (
        <div className="overlay">
          <div className="overlay-content">
            {!webOverlay.success && <div className="spinner"></div>}
            <p>{webOverlay.message}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default KnowledgeBoard;