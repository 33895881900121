import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Popup from './Popup';
import './UserParameter.css';
import { useNavigate, Link } from 'react-router-dom';
import WhatsAppIntegration from './WhatsAppIntegration';
import ChatbotSettings from './ChatbotSettings';
import CreateAgent from './CreateAgent';
import PurchaseTicketsButton from './PurchaseTicketsButton';
import { useTranslation } from 'react-i18next';
import { FaUser, FaTruck, FaEnvelope, FaLock, FaTrash, FaCog, FaInstagram, FaGoogle, FaWhatsapp, FaShopify, FaRobot } from 'react-icons/fa';

const UserParameter = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState(undefined);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [popupMessage, setPopupMessage] = useState('');
  const [isPasswordPopupVisible, setIsPasswordPopupVisible] = useState(false);
  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);
  const [confirmUsername, setConfirmUsername] = useState('');
  const [showEDNForm, setShowEDNForm] = useState(false);
  const [ednCredentials, setEdnCredentials] = useState({ edn_api_account: '', edn_api_key: '' });
  const [showWhatsAppIntegration, setShowWhatsAppIntegration] = useState(false); // State to show WhatsApp integration
  const [showChatbotSettings, setShowChatbotSettings] = useState(false); // State to show Chatbot Settings popup
  const [instagramAccounts, setInstagramAccounts] = useState([]); // Add this line
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      navigate('/'); // Redirect if no token found
      return;
    }

    fetchUserData(token);
  }, [navigate]);

  useEffect(() => {
    if (user === null) {
      navigate('/'); // Redirect if user data is explicitly null and not loading
    }
  }, [user, navigate]);

  const fetchUserData = async (token) => {
    try {
      const response = await axios.get('https://unanimityaiapp-1.onrender.com/user', {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data) {
        setUser({
          username: response.data.username || '',
          firstname: response.data.firstname || '',
          lastname: response.data.lastname || '',
          email: response.data.email || '',
          subscription_plan: response.data.subscription_plan || '',
          user_meta_id: response.data.user_meta_id || '',
          user_insta_id: response.data.user_insta_id || '',
          gmail_email_address: response.data.gmail_email || '',
          automation: response.data.automation || '',
          shop_name: response.data.shop_name || '',
          instagram_username: response.data.insta_username || '',
          whatsapp_phone_number: response.data.whatsapp_phone_number || '',
          edn_api_account: response.data.edn_api_account || '',
        });
      } else {
        setUser(null); // Ensure user is set to null if fetch fails
      }
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      setUser(null); // Ensure user is set to null on error
    }
  };

  const checkConnectionStatuses = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const instaStatus = urlParams.get('status');
    const googleStatus = urlParams.get('google_status');
    const shopifyStatus = urlParams.get('shopify_status');

    if (instaStatus === 'success') {
      setPopupMessage('Account connected successfully!');
    } else if (instaStatus === 'failure') {
      setPopupMessage('Failed to connect Instagram account.');
    }

    if (googleStatus === 'success') {
      setPopupMessage('Gmail connected successfully!');
    } else if (googleStatus === 'failure') {
      setPopupMessage('Failed to connect Google account.');
    }

    if (shopifyStatus === 'success') {
      setPopupMessage('Shopify store connected successfully!');
    } else if (shopifyStatus === 'failure') {
      setPopupMessage('Failed to connect Shopify account.');
    }
  };

  const handleAutomationToggle = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const newAutomationState = user.automation === 'on' ? 'off' : 'on';
      await axios.put('https://unanimityaiapp-1.onrender.com/update-automation', { automation: newAutomationState }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUser({ ...user, automation: newAutomationState });
    } catch (error) {
      console.error('Failed to update automation state:', error);
      alert('Error updating automation state.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      alert('New passwords do not match.');
      return;
    }

    try {
      const token = sessionStorage.getItem('access_token');
      await axios.put('https://unanimityaiapp-1.onrender.com/update-user', {
        currentPassword,
        password: newPassword,
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
      alert('Password updated successfully.');
      setIsPasswordPopupVisible(false);
    } catch (error) {
      console.error('Failed to update password:', error);
      alert('Error updating password.');
    }
  };

  const handleProfileUpdate = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.put('https://unanimityaiapp-1.onrender.com/update-user', {
        username: user.username,
        whatsapp_phone_number: user.whatsapp_phone_number
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      alert('Profile updated successfully.');
      fetchUserData(token);
    } catch (error) {
      console.error('Failed to update profile:', error);
      alert('Error updating profile.');
    }
  };

  const handleConnectEDN = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.post('https://unanimityaiapp-1.onrender.com/edn/connect', ednCredentials, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser({ ...user, edn_api_account: ednCredentials.edn_api_account });
      setPopupMessage('EDN connected successfully!');
      setShowEDNForm(false);
    } catch (error) {
      console.error('Failed to connect EDN account:', error);
      setPopupMessage('Failed to connect EDN account.');
    }
  };
  
  const handleDisconnectEDN = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.delete('https://unanimityaiapp-1.onrender.com/edn/disconnect', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser({ ...user, edn_api_account: '' });
      setPopupMessage('EDN disconnected successfully!');
    } catch (error) {
      console.error('Failed to disconnect EDN account:', error);
      setPopupMessage('Failed to disconnect EDN account.');
    }
  };

  const handleConnectInstagram = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('https://unanimityaiapp-1.onrender.com/instagram/connect', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      window.location.href = response.data.authorization_url;
    } catch (error) {
      console.error('Failed to connect Instagram account:', error);
    }
  };

  useEffect(() => {
    checkConnectionStatuses();

    // Check if the URL contains Instagram callback parameters
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    if (code && state) {
      handleInstagramCallback(code, state);
    }
  }, []);

  const handleInstagramCallback = async (code, state) => {
    const data = await fetchInstagramAccounts(code, state);
    if (data) {
      setInstagramAccounts(data.accounts);
    }
  };

  const fetchInstagramAccounts = async (code, state) => {
    try {
      const response = await axios.get(`https://unanimityaiapp-1.onrender.com/instagram/callback?code=${code}&state=${state}`);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch Instagram accounts:', error);
      return null;
    }
  };

  const handleAccountSelect = async (accountId, accountName) => {
    try {
      const token = sessionStorage.getItem('access_token');
      if (!token) {
        alert('You are not logged in. Please log in again.');
        navigate('/login');
        return;
      }

      const payload = {
        account_id: accountId,
        name: accountName
      };

      console.log('Sending payload:', payload);

      await axios.post('https://unanimityaiapp-1.onrender.com/instagram/select_account', payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      navigate('/parameters?status=success');
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert('Session expired. Please log in again.');
        navigate('/login');
      } else {
        console.error('Failed to select Instagram account:', error);
        alert('Failed to connect Instagram account.');
      }
    }
  };

  const handleConnectGoogle = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('https://unanimityaiapp-1.onrender.com/google/connect', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      window.location.href = response.data.authorization_url;
    } catch (error) {
      console.error('Failed to connect Google account:', error);
      setPopupMessage('Failed to connect Google account.');
    }
  };

  const handleConnectShopify = async () => {
    const shopName = prompt('Enter your Shopify shop name:');
    if (shopName) {
      try {
        const token = sessionStorage.getItem('access_token');
        const response = await axios.post('https://unanimityaiapp-1.onrender.com/shopify/connect', { shop_name: shopName }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        window.location.href = response.data.authorization_url;
      } catch (error) {
        console.error('Failed to connect Shopify account:', error);
        setPopupMessage('Failed to connect Shopify account.');
      }
    }
  };

  

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handlePopupClose = () => {
    setPopupMessage('');
    window.history.replaceState({}, document.title, window.location.pathname);
  };

  const togglePasswordPopup = () => {
    setIsPasswordPopupVisible(!isPasswordPopupVisible);
  };

  const handleUsernameChange = (e) => {
    setUser({ ...user, username: e.target.value });
  };

  const handleDisconnectInstagram = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.delete('https://unanimityaiapp-1.onrender.com/instagram/unsubscribe', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser({ ...user, user_insta_id: '', instagram_username: '' });
      setPopupMessage('Instagram account disconnected successfully!');
    } catch (error) {
      console.error('Failed to disconnect Instagram account:', error);
      setPopupMessage('Failed to disconnect Instagram account.');
    }
  };

  const handleDisconnectGoogle = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.delete('https://unanimityaiapp-1.onrender.com/google/unsubscribe', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser({ ...user, gmail_email_address: '' });
      setPopupMessage('Google account disconnected successfully!');
    } catch (error) {
      console.error('Failed to disconnect Google account:', error);
      setPopupMessage('Failed to disconnect Google account.');
    }
  };

  const handleDisconnectShopify = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.delete('https://unanimityaiapp-1.onrender.com/shopify/unsubscribe', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser({ ...user, shop_name: '' });
      setPopupMessage('Shopify account disconnected successfully!');
    } catch (error) {
      console.error('Failed to disconnect Shopify account:', error);
      setPopupMessage('Failed to disconnect Shopify account.');
    }
  };

  const handleDisconnectWhatsApp = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.delete('https://unanimityaiapp-1.onrender.com/whatsapp/unsubscribe', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser({ ...user, whatsapp_phone_number: '' });
      setPopupMessage('WhatsApp account disconnected successfully!');
    } catch (error) {
      console.error('Failed to disconnect WhatsApp account:', error);
      setPopupMessage('Failed to disconnect WhatsApp account.');
    }
  };

  const handleDeleteUser = async () => {
    if (confirmUsername !== user.username) {
      alert('Username does not match.');
      return;
    }

    try {
      const token = sessionStorage.getItem('access_token');
      await axios.delete('https://unanimityaiapp-1.onrender.com/user/delete', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      sessionStorage.removeItem('access_token');
      navigate('/');
    } catch (error) {
      console.error('Failed to delete user account:', error);
      alert('Error deleting user account.');
    }
  };

  const toggleDeletePopup = () => {
    setIsDeletePopupVisible(!isDeletePopupVisible);
  };
  const handleCloseWhatsAppIntegration = () => {
    setShowWhatsAppIntegration(false);
  };

  const handleRedirectToCheckout = () => {
    if (!user.email) {
      alert('Email not found. Please make sure you are logged in.');
      return;
    }
    const checkoutUrl = `https://buy.stripe.com/test_eVabKk5Cvc89bDOcMM?prefilled_email=${user.email}`;
    window.location.href = checkoutUrl;
  };

  const handleConnectWhatsApp = () => {
    setShowWhatsAppIntegration(true); // Show the WhatsApp integration component
  };

  const handleOpenChatbotSettings = () => {
    setShowChatbotSettings(true); // Show the Chatbot Settings component
  };

  const handleCloseChatbotSettings = () => {
    setShowChatbotSettings(false); // Hide the Chatbot Settings component
  };


  return (
    <div className="user-parameter-container">
      {user === undefined ? (
        <div className="loading">{t('userParameter.loading')}</div>
      ) : user ? (
        <>
          <div className="user-info-section">
            <h2 className="section-title"><FaUser /> {t('userParameter.userInfo')}</h2>
            <div className="user-info-grid">
              <div className="info-item">
                <FaUser className="info-icon" />
                <div>
                  <strong>{t('userParameter.username')}</strong>
                  <p>{user.username}</p>
                </div>
              </div>
              <div className="info-item">
                <FaEnvelope className="info-icon" />
                <div>
                  <strong>{t('userParameter.email')}</strong>
                  <p>{user.email}</p>
                </div>
              </div>
              {/* Add more user info items here */}
            </div>
            <button className="change-password-button" onClick={togglePasswordPopup}>
              <FaLock /> {t('userParameter.changePassword')}
            </button>
          </div>

          <div className="integrations-section">
            <h2 className="section-title"><FaCog /> {t('userParameter.integrations')}</h2>
            <div className="integration-buttons">
              <IntegrationButton
                icon={<FaInstagram />}
                label="Instagram"
                connected={!!user.user_insta_id}
                onConnect={handleConnectInstagram}
                onDisconnect={handleDisconnectInstagram}
              />
              <IntegrationButton
                icon={<FaGoogle />}
                label="Google"
                connected={!!user.gmail_email_address}
                onConnect={handleConnectGoogle}
                onDisconnect={handleDisconnectGoogle}
              />
              <IntegrationButton
                icon={<FaWhatsapp />}
                label="WhatsApp"
                connected={!!user.whatsapp_phone_number}
                onConnect={handleConnectWhatsApp}
                onDisconnect={handleDisconnectWhatsApp}
              />
              <IntegrationButton
                icon={<FaRobot />}
                label="Chatbot"
                connected={true}
                onConnect={handleOpenChatbotSettings}
              />
            </div>
          </div>

          <div className="subscription-section">
            <h2 className="section-title">{t('userParameter.subscriptionPlan')}</h2>
            <div className="subscription-info">
              <p>{user.subscription_plan}</p>
              <Link to="/billing" className="manage-subscription-button">
                {t('userParameter.manageSubscription')}
              </Link>
            </div>
          </div>
          <div className="edn-integration-section">
            <h2 className="section-title">EDN Integration</h2>
            <p>This feature is for users who need to integrate with Envoi du Net (EDN) for shipping services.</p>
            {user.edn_api_account ? (
              <div>
                <p>EDN is currently connected with account: {user.edn_api_account}</p>
                <button className="disconnect-button" onClick={handleDisconnectEDN}>
                  Disconnect EDN
                </button>
              </div>
            ) : (
              <button className="connect-button" onClick={() => setShowEDNForm(true)}>
                Connect EDN
              </button>
            )}
          </div>

          <div className="danger-zone">
            <h2 className="section-title">{t('userParameter.dangerZone')}</h2>
            <button className="delete-account-button" onClick={toggleDeletePopup}>
              <FaTrash /> {t('userParameter.deleteAccount')}
            </button>
          </div>
        </>
      ) : (
        <div className="error">{t('userParameter.loadError')}</div>
      )}

      {/* Modals */}
      {isPasswordPopupVisible && (
        <Modal onClose={togglePasswordPopup} title={t('userParameter.changePassword')}>
          <form onSubmit={handlePasswordSubmit} className="password-form">
            <div className="form-group">
              <label htmlFor="current-password">{t('userParameter.currentPassword')}</label>
              <input
                type="password"
                id="current-password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="new-password">{t('userParameter.newPassword')}</label>
              <input
                type="password"
                id="new-password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirm-new-password">{t('userParameter.confirmNewPassword')}</label>
              <input
                type="password"
                id="confirm-new-password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-actions">
              <button type="submit" className="primary-button">
                {t('userParameter.updatePassword')}
              </button>
            </div>
          </form>
        </Modal>
      )}

      {isDeletePopupVisible && (
        <Modal onClose={toggleDeletePopup} title={t('userParameter.deleteAccount')}>
          <form onSubmit={handleDeleteUser} className="delete-account-form">
            <p className="warning-text">{t('userParameter.deleteAccountWarning')}</p>
            <div className="form-group">
              <label htmlFor="confirm-username">{t('userParameter.confirmUsername')}</label>
              <input
                type="text"
                id="confirm-username"
                value={confirmUsername}
                onChange={(e) => setConfirmUsername(e.target.value)}
                required
              />
            </div>
            <div className="form-actions">
              <button type="submit" className="danger-button">
                {t('userParameter.confirmDelete')}
              </button>
            </div>
          </form>
        </Modal>
      )}

      {showWhatsAppIntegration && (
        <Modal onClose={handleCloseWhatsAppIntegration} title={t('userParameter.whatsAppIntegration')}>
          <WhatsAppIntegration />
        </Modal>
      )}

      {showEDNForm && (
        <Modal onClose={() => setShowEDNForm(false)} title="Connect EDN">
          <div className="edn-form">
            <div className="form-group">
              <label htmlFor="edn-api-account">EDN API Account</label>
              <input
                type="text"
                id="edn-api-account"
                value={ednCredentials.edn_api_account}
                onChange={(e) => setEdnCredentials({ ...ednCredentials, edn_api_account: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="edn-api-key">EDN API Key</label>
              <input
                type="password"
                id="edn-api-key"
                value={ednCredentials.edn_api_key}
                onChange={(e) => setEdnCredentials({ ...ednCredentials, edn_api_key: e.target.value })}
              />
            </div>
            <button className="connect-button" onClick={handleConnectEDN}>
              Connect EDN
            </button>
          </div>
        </Modal>
      )}

      {showChatbotSettings && (
        <Modal onClose={handleCloseChatbotSettings} title={t('userParameter.chatbotSettings')}>
          <ChatbotSettings />
        </Modal>
      )}
    </div>
  );
};

const IntegrationButton = ({ icon, label, connected, onConnect, onDisconnect }) => {
  const buttonClass = connected ? 'integration-button connected' : 'integration-button';
  const handleClick = connected ? onDisconnect : onConnect;

  return (
    <button className={buttonClass} onClick={handleClick}>
      {icon}
      <span>{label}</span>
      <span className="connection-status">{connected ? 'Connected' : 'Not Connected'}</span>
    </button>
  );
};

const Modal = ({ children, onClose, title }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>{title}</h2>
        <button className="close-button-circle" onClick={onClose}></button>
        {children}
      </div>
    </div>
  );
};

export default UserParameter;