import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './TagList.css';

const TagList = () => {
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchTags = async () => {
            try {
                const token = sessionStorage.getItem('access_token');
                const response = await axios.get('https://unanimityaiapp-1.onrender.com/user/tags', {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setTags(response.data.tags);
            } catch (error) {
                console.error('Failed to fetch tags:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchTags();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="tag-list">
            <h3>Ticket Tags</h3>
            <ul>
                {tags.map((tag, index) => (
                    <li key={index}>{tag}</li>
                ))}
            </ul>
        </div>
    );
};

export default TagList;
