import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Login from './components/Login';
import Signup from './components/Signup';
import Dashboard from './components/Dashboard';
import CreateAgent from './components/CreateAgent';
import MessageWindow from './components/MessageWindow';
import PrivateRoute from './components/PrivateRoute';
import AIAssistantManagement from './components/AIAssistantManagement';
import UserParameter from './components/UserParameter';
import Archive from './components/Archive';
import ResetPassword from './components/ResetPassword';
import ForgotPassword from './components/ForgotPassword';
import TagList from './components/TagList';
import PrivacyPolicy from './components/PrivacyPolicy.js';
import Payment from './components/Payment';
import InstagramAccountSelection from './components/InstagramAccountSelection';
import CreateForm from './components/CreateForm';
import FormResult from './components/FormResult';
import KnowledgeBoard from './components/KnowledgeBoard';
import Welcome from './components/Welcome';
import Chatbot from './components/ChatbotSettings';
import SentimentStatistics from './components/SentimentStatistics';
import Layout from './components/Layout';
import Billing from './components/ShopifyBillingTest';
import ShopifyCallback from './components/ShopifyCallback';
//import AppInitializer from './components/AppInitializer';
import LanguageSwitcher from './components/LanguageSwitcher';
import './App.css';


const LoginPage = () => (
  <div className="login-page-container">
    <LanguageSwitcher />
    <Login />
  </div>
);

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/shopify/callback" element={<ShopifyCallback />} />
  
          <Route
            path="/*"
            element={
              <PrivateRoute
                component={({ isAuthenticated, userRole }) => (
                  <Layout userRole={userRole} />
                )}
              />
            }
          >
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="parameters" element={<UserParameter />} />
            <Route path="create-agent" element={<CreateAgent />} roles={['admin']} />
            <Route path="tickets/:ticketId/messages" element={<MessageWindow />} />
            <Route path="ai-assistants" element={<AIAssistantManagement />} roles={['admin']} />
            <Route path="statistics" element={<SentimentStatistics />} roles={['admin']} />
            <Route path="billing" element={<Billing />} roles={['admin']} />
            <Route path="billing-success" element={<Billing />} roles={['admin']} />
            <Route path="billing-failed" element={<Billing />} roles={['admin']} />
            <Route path="chatbot" element={<Chatbot />} roles={['admin']} />
            <Route path="form" element={<CreateForm />} roles={['admin']} />
            <Route path="knowledge-board" element={<KnowledgeBoard />} roles={['admin']} />
            <Route path="form-result/:form_id" element={<FormResult />} roles={['admin']} />
            <Route path="payment" element={<Payment />} />
            <Route path="tickets/archive" element={<Archive />} roles={['admin']} />
            <Route path="tags" element={<TagList />} />
            <Route path="instagram/account-selection" element={<InstagramAccountSelection />} />
          </Route>
        </Routes>
      </Router>
  );
};

export default App;
