import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './MessageWindow.css';
import { useNavigate, useParams } from 'react-router-dom';
import InfoPanel from './InfoPanel';
import { useTranslation } from 'react-i18next';
import { FaPaperPlane, FaLightbulb, FaCheck, FaTimes, FaLanguage } from 'react-icons/fa';

const MessageWindow = () => {
    const { t } = useTranslation();
    const { ticketId } = useParams();
    const [ticket, setTicket] = useState(null);
    const [messages, setMessages] = useState([]);
    const [employee, setEmployee] = useState('');
    const [newMessage, setNewMessage] = useState('');
    const [editingMessage, setEditingMessage] = useState(null);
    const [editedContent, setEditedContent] = useState('');
    const [loading, setLoading] = useState(true);
    const [userLoading, setUserLoading] = useState(true);
    const [targetLanguage, setTargetLanguage] = useState('original');
    const [translatedMessages, setTranslatedMessages] = useState({});
    const [customerLoading, setCustomerLoading] = useState(false);
    const [orderLoading, setOrderLoading] = useState(false);
    const navigate = useNavigate();
    const [overlayState, setOverlayState] = useState({ show: false, message: '', success: false });
    const [user, setUser] = useState(null);
    const [clientInfo, setClientInfo] = useState('');
    const [orderDetails, setOrderDetails] = useState('');
    const [summary, setSummary] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [isTranslating, setIsTranslating] = useState(false);

    useEffect(() => {
        fetchUserData();
        fetchTicketAndMessages();
        const token = sessionStorage.getItem('access_token');
        const ws = new WebSocket(`wss://unanimityaiapp-1.onrender.com/ws?token=${token}`);

        ws.onmessage = (event) => {
            console.log('WebSocket message received:', event.data);
            fetchTicketAndMessages();
        };

        return () => {
            ws.close();
        };
    }, [ticketId]);

    const fetchUserData = async () => {
        try {
            const token = sessionStorage.getItem('access_token');
            const response = await axios.get('https://unanimityaiapp-1.onrender.com/user', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setUser(response.data);
        } catch (error) {
            console.error('Failed to fetch user data:', error);
        } finally {
            setUserLoading(false);
        }
    };

    const fetchTicketAndMessages = async () => {
        try {
            const token = sessionStorage.getItem('access_token');
            const response = await axios.get(`https://unanimityaiapp-1.onrender.com/tickets/${ticketId}/messages`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setTicket(response.data.ticket);
            setMessages(response.data.messages);
            setEmployee(response.data.employee);

            if (response.data.ticket.customer_id) {
                fetchCustomerInfo(response.data.ticket.customer_id, token);
            } else {
                setClientInfo('');
            }

            if (response.data.ticket.order) {
                fetchOrderInfo(response.data.ticket.order, token);
            } else {
                setOrderDetails('');
            }

            setSummary(response.data.ticket.summary || 'Summary information will be fetched/updated here.');
        } catch (error) {
            console.error('Failed to fetch ticket and messages:', error);
        } finally {
            setLoading(false);
        }
    };

    const formatMessageContent = (content) => {
        return content.split('\n').map((item, index) => (
            <span key={index}>
                {item}
                <br />
            </span>
        ));
    };
    
    const fetchCustomerInfo = async (customerId, token) => {
        setCustomerLoading(true);
        try {
            const response = await axios.get(`https://unanimityaiapp-1.onrender.com/get_customer`, {
                headers: { Authorization: `Bearer ${token}` },
                params: { customer_id: customerId }
            });
            const customer = response.data;
            setClientInfo(`Phone: ${customer.phone}\nFirst Name: ${customer.first_name}\nLast Name: ${customer.last_name}\nEmail: ${customer.email}`);
        } catch (error) {
            console.error('Failed to fetch customer info:', error);
        } finally {
            setCustomerLoading(false);
        }
    };

    const fetchOrderInfo = async (orderId, token) => {
        setOrderLoading(true);
        try {
            const response = await axios.get(`https://unanimityaiapp-1.onrender.com/get_shopify_order`, {
                headers: { Authorization: `Bearer ${token}` },
                params: { order_id: orderId }
            });
            const order = response.data;
            const formattedOrderDetails = `
Order Number: ${order.order_number}
Total Price: ${order.total_price}
Status: ${order.financial_status}
Created At: ${order.created_at}
Fulfillment Status: ${order.fulfillment_status}
Shipping Address:
    Name: ${order.shipping_address.name}
    Address1: ${order.shipping_address.address1}
    Address2: ${order.shipping_address.address2}
    City: ${order.shipping_address.city}
    Province: ${order.shipping_address.province}
    Country: ${order.shipping_address.country}
    Zip: ${order.shipping_address.zip}
Line Items:
${order.line_items.map(item => `
Title: ${item.title}
Quantity: ${item.quantity}
Price: ${item.price}
`).join('\n')}
`;
            setOrderDetails(formattedOrderDetails);
        } catch (error) {
            console.error('Failed to fetch order info:', error);
        } finally {
            setOrderLoading(false);
        }
    };

    const handleSendMessage = async () => {
        try {
            const token = sessionStorage.getItem('access_token');
            await axios.post('https://unanimityaiapp-1.onrender.com/message/add', {
                ticket_id: ticketId,
                content: newMessage,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setNewMessage('');
            fetchTicketAndMessages();
        } catch (error) {
            console.error('Failed to send message:', error);
        }
    };

    const handleGetAssistantResponse = async () => {
        try {
            const token = sessionStorage.getItem('access_token');
            const aiLogsString = JSON.stringify(ticket.ai_logs);
            console.log('Ticket Data:', JSON.stringify(ticket, null, 2));
            console.log('User Token:', token);
            console.log('New Message:', newMessage);
            console.log('AI Logs:', aiLogsString);

            const response = await axios.post('https://unanimityaiapp-1.onrender.com/writer_assistant', {
                text: newMessage,
                ai_logs: aiLogsString,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setNewMessage(response.data.response);
        } catch (error) {
            console.error('Failed to get assistant response:', error);
        }
    };

    const handleResolveTicket = async () => {
        setOverlayState({ show: true, message: t('messageWindow.processing'), success: false });
        try {
            const token = sessionStorage.getItem('access_token');
            await axios.put(`https://unanimityaiapp-1.onrender.com/tickets/${ticketId}/resolve`, null, {
                headers: { Authorization: `Bearer ${token}` },
            });
            await fetchTicketAndMessages();
            setOverlayState({ show: true, message: t('messageWindow.resolveSuccess'), success: true });
        } catch (error) {
            console.error('Failed to resolve ticket:', error);
            setOverlayState({ show: true, message: t('messageWindow.resolveError'), success: false });
        } finally {
            setTimeout(() => setOverlayState({ show: false, message: '', success: false }), 2000);
        }
    };
    
    const handleCloseTicket = async () => {
        setOverlayState({ show: true, message: t('messageWindow.processing'), success: false });
        try {
            const token = sessionStorage.getItem('access_token');
            await axios.put(`https://unanimityaiapp-1.onrender.com/tickets/${ticketId}/close`, null, {
                headers: { Authorization: `Bearer ${token}` },
            });
            await fetchTicketAndMessages();
            setOverlayState({ show: true, message: t('messageWindow.closeSuccess'), success: true });
        } catch (error) {
            console.error('Failed to close ticket:', error);
            setOverlayState({ show: true, message: t('messageWindow.closeError'), success: false });
        } finally {
            setTimeout(() => setOverlayState({ show: false, message: '', success: false }), 2000);
        }
    };

    const handleSendDraftMessage = async (messageId) => {
        try {
            const token = sessionStorage.getItem('access_token');
            await axios.post(`https://unanimityaiapp-1.onrender.com/message/send`, { message_id: messageId }, {
                headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
            });
            fetchTicketAndMessages();
        } catch (error) {
            console.error('Failed to send draft message:', error);
        }
    };

    const handleDeleteDraftMessage = async (messageId) => {
        try {
            const token = sessionStorage.getItem('access_token');
            await axios.post(`https://unanimityaiapp-1.onrender.com/message/delete`, { message_id: messageId }, {
                headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
            });
            fetchTicketAndMessages();
        } catch (error) {
            console.error('Failed to delete draft message:', error);
        }
    };

    const handleModifyDraftMessage = (message) => {
        setEditingMessage(message);
        setEditedContent(message.content);
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const handleSaveModifiedMessage = async () => {
        try {
            const token = sessionStorage.getItem('access_token');
            await axios.post(`https://unanimityaiapp-1.onrender.com/message/edit`, {
                message_id: editingMessage._id,
                content: editedContent,
            }, {
                headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
            });
            setEditingMessage(null);
            setEditedContent('');
            fetchTicketAndMessages();
        } catch (error) {
            console.error('Failed to modify draft message:', error);
        }
    };

    const handleCancelModification = () => {
        setEditingMessage(null);
        setEditedContent('');
    };

    useEffect(() => {
        if (!userLoading && !user) {
            navigate('/');
        }
    }, [userLoading, user, navigate]);

    if (loading || customerLoading || orderLoading) {
        return <div>{t('messageWindow.loading')}</div>;
    }

    if (!ticket) {
        return <div>{t('messageWindow.failedToLoadTicket')}</div>;
    }

    const isEditable = !['resolved', 'closed', 'ai_resolved'].includes(ticket.status);
    
    const handleLanguageChange = async (language) => {
        setTargetLanguage(language);
        if (language !== 'original') {
            setIsTranslating(true);
            await translateMessages(language);
            setIsTranslating(false);
        } else {
            setTranslatedMessages({});
        }
    };

    const translateMessages = async (language) => {
        try {
            const token = sessionStorage.getItem('access_token');
            const response = await axios.post('https://unanimityaiapp-1.onrender.com/translate_messages', {
                messages: messages.map(m => ({ id: m._id, content: m.content })),
                target_language: language
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const translatedObj = {};
            response.data.translated_messages.forEach(m => {
                translatedObj[m.id] = m.content;
            });
            setTranslatedMessages(translatedObj);
        } catch (error) {
            console.error('Failed to translate messages:', error);
        }
    };

    // Modify the message rendering to use translated content when available
    const renderMessageContent = (message) => {
        const content = targetLanguage === 'original' ? message.content : (translatedMessages[message._id] || message.content);
        return formatMessageContent(content);
    };
    const renderMessage = (message) => {
        const isSent = message.sender === 'employee' || message.status === 'sent' || message.status === 'draft';
        const messageClass = `message ${isSent ? 'sent' : 'received'} ${message.status}`;
        
        return (
          <div key={message._id} className={messageClass}>
            {message.status === 'automated' && (
              <span className="message-status-badge automated">{t('messageWindow.automated')}</span>
            )}
            <div className="message-content">
              {renderMessageContent(message)}
            </div>
            <div className="message-meta">
              <span className="message-status">
                {message.status 
                  ? t(`messageWindow.statusOptions.${message.status}`)
                  : t('messageWindow.statusOptions.received')}
              </span>
              <span className="message-timestamp">{formatDate(message.created_at)}</span>
            </div>
            {message.status === 'draft' && isEditable && (
              <div className="draft-actions">
                <button className="message-window-button draft-send" onClick={() => handleSendDraftMessage(message._id)}>{t('messageWindow.send')}</button>
                <button className="message-window-button draft-delete" onClick={() => handleDeleteDraftMessage(message._id)}>{t('messageWindow.delete')}</button>
                <button className="message-window-button draft-modify" onClick={() => handleModifyDraftMessage(message)}>{t('messageWindow.modify')}</button>
              </div>
            )}
          </div>
        );
      };

    return (
        <div className="message-window-container">
            <div className="message-window">
                <div className="ticket-header">
                    <h2>{t('messageWindow.ticket')}: {ticket.subject}</h2>
                    <div className="ticket-meta">
                        <span className={`ticket-status ${ticket.status}`}>
                            {t(`messageWindow.statusOptions.${ticket.status}`, ticket.status)}
                        </span>
                        <span className="ticket-employee">{t('messageWindow.employee')}: {employee}</span>
                    </div>
                </div>
                
                <div className="language-selector-container">
                    <FaLanguage className="language-icon" />
                    <select 
                        className="message-window-language-selector"
                        value={targetLanguage} 
                        onChange={(e) => handleLanguageChange(e.target.value)}
                    >
                        <option value="original">{t('messageWindow.original')}</option>
                        <option value="en">English</option>
                        <option value="fr">Français</option>
                        <option value="es">Español</option>
                    </select>
                    {isTranslating && <span className="translating-message">{t('messageWindow.translating')}</span>}
                </div>

                <div className="message-list">
                    {messages.map((message) => renderMessage(message))}
                    </div>

                {isEditable && (
                    <div className="response-input">
                        <textarea
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            placeholder={t('messageWindow.typeYourResponse')}
                            className="message-window-textarea"
                        ></textarea>
                        <div className="response-actions">
                            <button className="message-window-button send-button" onClick={handleSendMessage}>
                                <FaPaperPlane /> {t('messageWindow.send')}
                            </button>
                            <button className="message-window-button ai-help-button" onClick={handleGetAssistantResponse}>
                                <FaLightbulb /> {t('messageWindow.aiHelp')}
                            </button>
                        </div>
                    </div>
                )}

                {isEditable && !isProcessing && (
                    <div className="ticket-actions">
                    <button className="message-window-button resolve-button" onClick={handleResolveTicket}>
                        <FaCheck /> {t('messageWindow.resolveTicket')}
                    </button>
                    <button className="message-window-button close-button" onClick={handleCloseTicket}>
                        <FaTimes /> {t('messageWindow.closeTicket')}
                    </button>
                </div>
                )}

                {isProcessing && (
                    <div className="processing-message">{t('messageWindow.processing')}</div>
                )}
                {overlayState.show && (
                    <div className="overlay">
                        <div className="overlay-content">
                            {!overlayState.success && <div className="spinner"></div>}
                            <p>{overlayState.message}</p>
                        </div>
                    </div>
                )}

                {editingMessage && (
                    <div className="modal">
                        <div className="modal-content">
                            <h3>{t('messageWindow.modifyDraftMessage')}</h3>
                            <textarea
                                value={editedContent}
                                onChange={(e) => setEditedContent(e.target.value)}
                                className="message-window-textarea"
                            ></textarea>
                            <div className="modal-actions">
                                <button className="message-window-button save-button" onClick={handleSaveModifiedMessage}>{t('messageWindow.save')}</button>
                                <button className="message-window-button cancel-button" onClick={handleCancelModification}>{t('messageWindow.cancel')}</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <InfoPanel clientInfo={clientInfo} orderDetails={orderDetails} summary={summary} />
        </div>
    );
};

export default MessageWindow;