import React, { useState } from 'react';
import axios from 'axios';
import './TicketTableArchive.css';
import { useTranslation } from 'react-i18next';
import { FaInstagram, FaFacebookMessenger, FaWhatsapp, FaEnvelope, FaSort, FaSortUp, FaSortDown, FaSearch } from 'react-icons/fa';

const TicketTableArchive = ({ tickets, user, onTicketClick }) => {
  const { t } = useTranslation();
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortField, setSortField] = useState('created_at');
  const [statusFilter, setStatusFilter] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [initialLoading, setInitialLoading] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchError, setSearchError] = useState(null);

  const channelIcons = {
    Instagram: FaInstagram,
    Messenger: FaFacebookMessenger,
    WhatsApp: FaWhatsapp,
    Gmail: FaEnvelope
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const sortTickets = (tickets, field, order) => {
    return [...tickets].sort((a, b) => {
      const valA = a[field];
      const valB = b[field];
      if (field === 'created_at') {
        return order === 'asc' ? new Date(valA) - new Date(valB) : new Date(valB) - new Date(valA);
      }
      if (field === 'sentiment_score') {
        return order === 'asc' ? (valA || 0) - (valB || 0) : (valB || 0) - (valA || 0);
      }
      return order === 'asc' ? valA.localeCompare(valB) : valB.localeCompare(valA);
    });
  };

  const filterTickets = (tickets) => {
    return tickets.filter(ticket => {
      const statusMatch = statusFilter ? ticket.status === statusFilter : true;
      return statusMatch;
    });
  };

  const handleSearch = async () => {
    if (!searchQuery.trim()) {
      setSearchError(t('ticketTableArchive.enterSearchQuery'));
      return;
    }
    setIsSearching(true);
    setSearchError(null);
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('https://unanimityaiapp-1.onrender.com/search/', {
        params: { query: searchQuery },
        headers: { Authorization: `Bearer ${token}` }
      });
      
      setSearchResults(response.data);
    } catch (error) {
      console.error('Error searching tickets:', error);
      if (error.response && error.response.status === 500) {
        setSearchError(t('ticketTableArchive.internalServerError'));
      } else if (error.request) {
        setSearchError(t('ticketTableArchive.noResponseReceived'));
      } else {
        setSearchError(t('ticketTableArchive.searchError'));
      }
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  };

  const ticketsToDisplay = searchResults.length > 0 ? searchResults : tickets;
  const sortedTickets = sortTickets(filterTickets(ticketsToDisplay), sortField, sortOrder);

  const handleSortChange = (field) => {
    setSortField(field);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const getSentimentScoreClass = (score) => {
    if (score === null) return 'null';
    if (score <= 25) return 'low';
    if (score <= 60) return 'medium';
    return 'high';
  };

  const SortIcon = ({ field }) => {
    if (sortField !== field) return <FaSort />;
    return sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />;
  };

  return (
    <div className="ticket-table-archive">
      <div className="table-controls">
        <div className="status-filter">
          <label htmlFor="status-select">{t('ticketTableArchive.status')}:</label>
          <select
            id="status-select"
            value={statusFilter}
            onChange={handleStatusFilterChange}
          >
            <option value="">{t('ticketTableArchive.all')}</option>
            <option value="closed">{t('ticketTableArchive.closed')}</option>
            <option value="resolved">{t('ticketTableArchive.resolved')}</option>
            <option value="ai_resolved">{t('ticketTableArchive.aiResolved')}</option>
          </select>
        </div>
        <div className="search-container">
          <input
            type="text"
            className="search-input"
            placeholder={t('ticketTableArchive.enterSearchQuery')}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button
            className="search-button"
            onClick={handleSearch}
            disabled={isSearching}
          >
            <FaSearch /> {isSearching ? t('ticketTableArchive.searching') : t('ticketTableArchive.search')}
          </button>
        </div>
      </div>

      {searchError && <div className="error-message">{searchError}</div>}

      <table>
        <thead>
          <tr>
            <th>{t('ticketTableArchive.channel')}</th>
            <th>{t('ticketTableArchive.subject')}</th>
            <th onClick={() => handleSortChange('sentiment_score')} className="sortable">
              {t('ticketTableArchive.sentimentScore')} <SortIcon field="sentiment_score" />
            </th>
            <th>{t('ticketTableArchive.status')}</th>
            <th onClick={() => handleSortChange('created_at')} className="sortable">
              {t('ticketTableArchive.createdAt')} <SortIcon field="created_at" />
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedTickets.length > 0 ? (
            sortedTickets.map((ticket) => {
              const ChannelIcon = channelIcons[ticket.channel_name];
              return (
                <tr key={ticket._id} onClick={() => onTicketClick(ticket._id)}>
                  <td>
                    {ChannelIcon && <ChannelIcon className="channel-icon" />}
                    <span>{ticket.channel_name}</span>
                  </td>
                  <td>{ticket.subject}</td>
                  <td>
                    <span className={`sentiment-score ${getSentimentScoreClass(ticket.sentiment_score)}`}>
                      {ticket.sentiment_score !== null ? Math.round(ticket.sentiment_score) : t('ticketTableArchive.notAvailable')}
                    </span>
                  </td>
                  <td>
                    <span className={`status-badge ${ticket.status}`}>
                      {t(`ticketTableArchive.${ticket.status}`)}
                    </span>
                  </td>
                  <td>{formatDate(ticket.created_at)}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="5" className="no-tickets-message">
                {searchResults.length === 0 && searchQuery ? t('ticketTableArchive.noMatchingTickets') : t('ticketTableArchive.noTicketsAvailable')}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TicketTableArchive;