import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './FormResult.css';

const FormResult = () => {
  const { t } = useTranslation();
  const { form_id } = useParams();
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      navigate('/');
      return;
    }

    fetchUserData(token);
  }, [navigate]);

  const fetchUserData = async (token) => {
    try {
      const response = await axios.get('https://unanimityaiapp-1.onrender.com/user', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUser(response.data);
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      setError(t('formResult.fetchUserDataError'));
    }
  };

  useEffect(() => {
    if (user) {
      fetchFormResults();
    }
  }, [user]);

  const fetchFormResults = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get(`https://unanimityaiapp-1.onrender.com/get-form-results/${form_id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setResults(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch form results:', error);
      setError(t('formResult.fetchResultsError'));
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="loading">{t('formResult.loading')}</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (results.length === 0) {
    return <div className="form-result-container"><p>{t('formResult.noResults')}</p></div>;
  }

  const headers = Object.keys(results[0].results);

  return (
    <div className="form-result-container">
      <h2>{t('formResult.title')}</h2>
      <table className="results-table">
        <thead>
          <tr>
            <th>{t('formResult.submittedAt')}</th>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {results.map((result, index) => (
            <tr key={index}>
              <td>{new Date(result.submitted_at).toLocaleString()}</td>
              {headers.map((header, idx) => (
                <td key={idx}>{result.results[header]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FormResult;