import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale,
    Title,
    Tooltip,
    Legend,
    BarElement,
    ArcElement
} from 'chart.js';
import './SentimentStatistics.css';
import { useTranslation } from 'react-i18next';
import { FaChartLine, FaChartBar, FaChartPie, FaClock } from 'react-icons/fa';

ChartJS.register(
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale,
    Title,
    Tooltip,
    Legend,
    BarElement,
    ArcElement
);

const SentimentStatistics = () => {
    const { t } = useTranslation();
    const [statistics, setStatistics] = useState(null);
    const [monthlyUsage, setMonthlyUsage] = useState({ tickets_used: 0, extra_tickets_used: 0, ticket_limit: 0 });
    const [ticketStats, setTicketStats] = useState({
        ai_resolved_tickets: 0,
        assigned_tickets: 0,
        open_tickets: 0,
        pending_tickets: 0,
        closed_tickets: 0,
        resolved_tickets: 0
    });
    const [timePeriod, setTimePeriod] = useState('7_days');
    const [rollingWindow, setRollingWindow] = useState('raw');
    const token = sessionStorage.getItem('access_token');

    useEffect(() => {
        const fetchStatistics = async () => {
            try {
                const response = await axios.get('https://unanimityaiapp-1.onrender.com/tickets/sentiment-statistics', {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setStatistics(response.data);
            } catch (error) {
                console.error('Failed to fetch sentiment statistics:', error);
            }
        };

        const fetchMonthlyUsage = async () => {
            try {
                const response = await axios.get('https://unanimityaiapp-1.onrender.com/monthly-usage', {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setMonthlyUsage(response.data || { tickets_used: 0, extra_tickets_used: 0, ticket_limit: 0 });
            } catch (error) {
                console.error('Failed to fetch monthly usage:', error);
            }
        };

        const fetchTicketStats = async () => {
            try {
                const response = await axios.get('https://unanimityaiapp-1.onrender.com/ticket-stats', {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setTicketStats(response.data || {
                    ai_resolved_tickets: 0,
                    assigned_tickets: 0,
                    open_tickets: 0,
                    pending_tickets: 0,
                    closed_tickets: 0,
                    resolved_tickets: 0
                });
            } catch (error) {
                console.error('Failed to fetch ticket stats:', error);
            }
        };

        fetchStatistics();
        fetchMonthlyUsage();
        fetchTicketStats();
    }, [token]);

    const handleTimePeriodChange = (e) => {
        setTimePeriod(e.target.value);
    };

    const handleRollingWindowChange = (e) => {
        setRollingWindow(e.target.value);
    };

    if (!statistics) {
        return <div>{t('sentimentStats.loading')}</div>;
    }

    const getSentimentData = () => {
        if (timePeriod === 'monthly') {
            return statistics.sentiment_statistics['monthly'];
        } else {
            return statistics.sentiment_statistics[timePeriod][rollingWindow];
        }
    };

    const getResolvedData = () => {
        if (timePeriod === 'monthly') {
            return statistics.resolved_statistics['monthly'];
        } else {
            return statistics.resolved_statistics[timePeriod][rollingWindow];
        }
    };

    const generateChartData = (data, label) => ({
        labels: data.map(entry => entry._id),
        datasets: [{
            label: label,
            data: data.map(entry => entry.average_sentiment ?? 0),
            fill: false,
            borderColor: 'rgba(75,192,192,1)',
            tension: 0.1
        }]
    });

    const generateResolvedData = (data) => ({
        labels: data.map(entry => entry._id),
        datasets: [
            {
                label: 'Resolved',
                data: data.map(entry => entry.resolved ?? 0),
                fill: false,
                borderColor: 'rgba(54,162,235,1)',
                tension: 0.1
            },
            {
                label: 'AI Resolved',
                data: data.map(entry => entry.ai_resolved ?? 0),
                fill: false,
                borderColor: 'rgba(255,99,132,1)',
                tension: 0.1
            }
        ]
    });

    const selectedSentimentData = getSentimentData();
    const selectedResolvedData = getResolvedData();

    const monthlyUsageData = {
        labels: [t('sentimentStats.ticketsUsed'), t('sentimentStats.extraTicketsUsed')],
        datasets: [
            {
                label: t('sentimentStats.monthlyTicketUsage'),
                data: [monthlyUsage.tickets_used, monthlyUsage.extra_tickets_used],
                backgroundColor: ['#FF6384', '#36A2EB']
            }
        ]
    };

    const ticketStatsData = {
        labels: [
            t('sentimentStats.aiResolved'),
            t('sentimentStats.assigned'),
            t('sentimentStats.open'),
            t('sentimentStats.pending'),
            t('sentimentStats.closed'),
            t('sentimentStats.resolved')
        ],
        datasets: [
            {
                label: t('sentimentStats.ticketStatistics'),
                data: [
                    ticketStats.ai_resolved_tickets,
                    ticketStats.assigned_tickets,
                    ticketStats.open_tickets,
                    ticketStats.pending_tickets,
                    ticketStats.closed_tickets,
                    ticketStats.resolved_tickets
                ],
                backgroundColor: ['#008000', '#36A2EB', '#FFA500', '#800080', '#808080', '#0000FF']
            }
        ]
    };

    const barChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 4,
        plugins: {
            legend: { display: false }
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 50,
                    precision: 0
                }
            }
        }
    };

    const doughnutChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 4,
        cutout: '50%',
        plugins: {
            legend: { position: 'right' }
        }
    };

    return (
        <div className="sentiment-statistics">
            <h1 className="page-title"><FaChartLine /> {t('sentimentStats.title')}</h1>
            
            <div className="controls-card">
                <div className="control-group">
                    <label htmlFor="time-period">
                        <FaClock /> {t('sentimentStats.selectTimePeriod')}:
                    </label>
                    <select id="time-period" value={timePeriod} onChange={handleTimePeriodChange}>
                        <option value="7_days">{t('sentimentStats.last7Days')}</option>
                        <option value="14_days">{t('sentimentStats.last14Days')}</option>
                        <option value="30_days">{t('sentimentStats.last30Days')}</option>
                        <option value="monthly">{t('sentimentStats.monthly')}</option>
                    </select>
                </div>
                {timePeriod !== 'monthly' && (
                    <div className="control-group">
                        <label htmlFor="rolling-window">
                            <FaChartLine /> {t('sentimentStats.selectRollingWindow')}:
                        </label>
                        <select id="rolling-window" value={rollingWindow} onChange={handleRollingWindowChange}>
                            <option value="raw">{t('sentimentStats.raw')}</option>
                            <option value="rolling_2">{t('sentimentStats.rolling2Days')}</option>
                            <option value="rolling_3">{t('sentimentStats.rolling3Days')}</option>
                            <option value="rolling_5">{t('sentimentStats.rolling5Days')}</option>
                        </select>
                    </div>
                )}
            </div>

            <div className="charts-grid">
                <div className="chart-card">
                    <h3><FaChartBar /> {t('sentimentStats.monthlyTicketUsage')}</h3>
                    <Bar data={monthlyUsageData} options={barChartOptions} />
                </div>
                <div className="chart-card">
                    <h3><FaChartPie /> {t('sentimentStats.ticketStatistics')}</h3>
                    <Doughnut data={ticketStatsData} options={doughnutChartOptions} />
                </div>
                <div className="chart-card">
                    <h3><FaChartLine /> {t('sentimentStats.sentimentOverTime')}</h3>
                    <Line data={generateChartData(selectedSentimentData, t('sentimentStats.averageSentiment'))} />
                </div>
                <div className="chart-card">
                    <h3><FaChartLine /> {t('sentimentStats.resolvedVsAiResolved')}</h3>
                    <Line data={generateResolvedData(selectedResolvedData)} />
                </div>
            </div>
        </div>
    );
};

export default SentimentStatistics;