import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CreateForm.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaWpforms, FaPlus, FaTrash, FaTimes } from 'react-icons/fa';

const CreateForm = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState(undefined);
  const [title, setTitle] = useState('');
  const [questions, setQuestions] = useState([{ label: '', placeholder: '' }]);
  const [forms, setForms] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      navigate('/');
      return;
    }

    fetchUserData(token);
  }, [navigate]);

  useEffect(() => {
    if (user) {
      const token = sessionStorage.getItem('access_token');
      fetchForms(token);
    }
  }, [user]);

  const fetchUserData = async (token) => {
    try {
      const response = await axios.get('https://unanimityaiapp-1.onrender.com/user', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUser(response.data);
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      setUser(null);
    }
  };

  const fetchForms = async (token) => {
    try {
      const response = await axios.get('https://unanimityaiapp-1.onrender.com/list-forms', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setForms(response.data.forms);
    } catch (error) {
      console.error('Failed to fetch forms:', error);
    }
  };

  const handleAddQuestion = () => {
    setQuestions([...questions, { label: '', placeholder: '' }]);
  };

  const handleRemoveQuestion = (index) => {
    const newQuestions = questions.filter((_, i) => i !== index);
    setQuestions(newQuestions);
  };

  const handleQuestionChange = (index, key, value) => {
    const newQuestions = questions.map((question, i) =>
      i === index ? { ...question, [key]: value } : question
    );
    setQuestions(newQuestions);
  };

  const handleCreateForm = async (e) => {
    e.preventDefault();
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.post(
        'https://unanimityaiapp-1.onrender.com/create-form/',
        { form_schema: { title, fields: questions.map(q => ({ type: 'text', label: q.label, placeholder: q.placeholder })) } },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setTitle('');
      setQuestions([{ label: '', placeholder: '' }]);
      fetchForms(token);
      setShowPopup(false);
      alert('Form created successfully');
    } catch (error) {
      console.error('Failed to create form:', error);
    }
  };

  const handleDeleteForm = async (formId) => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.delete(`https://unanimityaiapp-1.onrender.com/delete-form/${formId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchForms(token);
      alert('Form deleted successfully');
    } catch (error) {
      console.error('Failed to delete form:', error);
    }
  };

  const handleFormClick = (formId) => {
    navigate(`/form-result/${formId}`);
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  if (user === undefined) {
    return <div className="create-form-loading">{t('createForm.loading')}</div>;
  }

  if (user.subscription_plan === 'free') {
    return (
      <div className="create-form-no-access">
        <p>{t('createForm.noAccessMessage')}</p>
        <button onClick={() => navigate('/Payment')} className="create-form-upgrade-button">
          {t('createForm.upgradeNow')}
        </button>
      </div>
    );
  }

  return (
    <div className="create-form-container">
      <h2 className="section-title">
        <FaWpforms />
        {t('createForm.title')}
      </h2>
        <div className="create-form-list">
          {forms.map((form) => (
            <div key={form.form_id} className="create-form-card" onClick={() => handleFormClick(form.form_id)}>
              <div className="create-form-title">{form.title}</div>
              <button
                className="create-form-delete-button"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteForm(form.form_id);
                }}
                aria-label={t('createForm.deleteFormAriaLabel', { title: form.title })}
              >
                <FaTrash />
              </button>
            </div>
          ))}
        </div>
        <button className="create-form-new-button" onClick={togglePopup}>
          <FaPlus /> {t('createForm.createNewForm')}
        </button>

      {showPopup && (
        <div className="create-form-popup">
          <div className="create-form-popup-content">
            <h2>{t('createForm.createForm')}</h2>
            <form onSubmit={handleCreateForm} className="create-form-form">
              <div className="create-form-group">
                <label htmlFor="title">{t('createForm.title')}</label>
                <input
                  type="text"
                  id="title"
                  placeholder={t('createForm.enterFormTitle')}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </div>
              {questions.map((question, index) => (
                <div key={index} className="create-form-question-group">
                  <div className="create-form-group">
                    <label htmlFor={`label-${index}`}>{t('createForm.question')}</label>
                    <input
                      type="text"
                      id={`label-${index}`}
                      placeholder={t('createForm.enterQuestion')}
                      value={question.label}
                      onChange={(e) => handleQuestionChange(index, 'label', e.target.value)}
                      required
                    />
                  </div>
                  <div className="create-form-group">
                    <label htmlFor={`placeholder-${index}`}>{t('createForm.placeholder')}</label>
                    <input
                      type="text"
                      id={`placeholder-${index}`}
                      placeholder={t('createForm.enterPlaceholder')}
                      value={question.placeholder}
                      onChange={(e) => handleQuestionChange(index, 'placeholder', e.target.value)}
                    />
                  </div>
                  <button type="button" onClick={() => handleRemoveQuestion(index)} className="create-form-remove-question">
                    <FaTrash /> {t('createForm.removeQuestion')}
                  </button>
                </div>
              ))}
              <button type="button" onClick={handleAddQuestion} className="create-form-add-question">
                <FaPlus /> {t('createForm.addQuestion')}
              </button>
              <div className="create-form-actions">
                <button type="submit" className="create-form-submit">
                  {t('createForm.createForm')}
                </button>
                <button type="button" className="create-form-cancel" onClick={togglePopup}>
                  <FaTimes /> {t('createForm.cancel')}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateForm;